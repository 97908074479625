import { useContext } from 'react';
import ResultsContext from '../../../context/ResultsContext';

import '../Results.scss';
import {roundNumber} from "../../../helpers/rounding";

export default function ResultsOverview() {
  const { resultsContent } = useContext(ResultsContext);

  function formatTotalPrice(raw_number) {
    const number = +raw_number;
    if (Math.abs(number) >= 1000000000) {
      const roundedNumber = Math.round(number / 10000000) / 100;
      return `${roundedNumber.toFixed(1)}B`;
    } else if (Math.abs(number) >= 1000000) {
      const roundedNumber = Math.round(number / 10000) / 100;
      return `${roundedNumber.toFixed(1)}M`;
    } else {
      return number.toString();
    }
  }

  return (
    <div className={`
      ResultsOverview
      ${resultsContent.augmentation_summary ? "two-cards" : null}
      ${resultsContent.service_life ? "one-card" : null}
    `}>

      {resultsContent.augmentation_summary ?
        <div className="left-summary card-basic white">
          <div>
            <p className="text-red">Initial Installation</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <p className="text-blue"><span>{parseFloat(resultsContent.augmentation_summary[0].total_ts.toFixed(0))}</span>&nbsp;TeraStors</p>
              <p className="text-blue">List Price: ${formatTotalPrice(resultsContent.total_price)}*</p>
            </div>
          </div>
          <div>
            <p className="text-light" style={{ fontSize: 12, fontWeight: '500', display: "block", marginBottom: 5}}><strong style={{ fontSize: 16, fontWeight: '700', color: '#003875', marginRight: 3, whiteSpace: 'nowrap'}}>* 5 yrs. included warranty </strong>  and preventative maintenance—extendable to 20 yrs!</p>
            <p className="text-light" style={{ fontSize: 12, fontWeight: '500', display: "block" }}><strong style={{ fontSize: 16, fontWeight: '400', color: '#003875', marginRight: 3, whiteSpace: 'nowrap'}}>  Does not include duty or freight</strong>; subject to AESI’s standard terms & conditions.</p>
          </div>
        </div>
          : null}

      <div className="right-summary" style={{ marginLeft: 40}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {resultsContent.service_life ?
            <div className="summary-card card-basic white">
              <p className="text-red">Service Life</p>
              <p className="text-blue"><span>{resultsContent.service_life}</span>&nbsp;years</p>
            </div> : null}
          <div className="summary-card card-basic white">
            <p className="text-red">List Price / kWh AC</p>
            <p className="text-blue"><span>${roundNumber(resultsContent.total_price/ (resultsContent.terastor_ac_terminals?.usable_energy.delivered*1000))}</span><span style={{fontSize:24}}>*</span>&nbsp;</p>
          </div>
        </div>
        <div className="disclaimer-card card-basic white text-red" style={{ fontSize: '14px' }}>
          Estimate using your basic assumptions. Please submit a “GET QUOTE” request to receive a customized plan to suit your exact needs.
        </div>
      </div>

    </div>
  )
}