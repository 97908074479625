import { useContext } from 'react';
import ResultsContext from '../../../context/ResultsContext';
import SubmissionFormContext from '../../../context/SubmissionFormContext';
import OverlayContext from '../../../context/OverlayContext';
import { useNavigate } from "react-router-dom";
import logo from '../../../images/logo.jpg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './NavBar.scss'

export default function NavBar({ setShowContactForm }) {
  const { formContent } = useContext(SubmissionFormContext);
  const { resultsContent } = useContext(ResultsContext);
  const { showOverlay } = useContext(OverlayContext);
  const navigate = useNavigate();
  let screenShotMessageSent = false;

  const vizFrame = document.getElementById("visualizationIFrame");
  const vizImage = document.getElementById("vizImage");
  window.addEventListener('message', message => {
    if(screenShotMessageSent && message.data.match(/data:image.*/)){
      screenShotMessageSent = false;

      vizFrame.style.setProperty("display", "none");
      vizImage.style.setProperty("display", "block");
      vizImage.src = message.data;

      captureAndDownloadPDF();
    }
  });

  const sendVisualisationScreenShotMessage = () => {
    vizFrame.contentWindow.postMessage("__TAKE_SCREENSHOT__", "*");
    screenShotMessageSent = true;
  };

  const captureAndDownloadPDF = () => {
    let resultsWrapper = document.querySelector(".results-wrapper");
    let resultsWrapperMaxW = document.querySelector(".results-max-w");
    let submissionForm = document.querySelector(".SubmissionForm");
    let navBar = document.querySelector(".NavBar");
    let openConfig = document.querySelector(".show-config");
    let bolEolOffset = window.getComputedStyle(document.querySelector('.toggle_bol_eol_flag'), ':before').getPropertyValue("--highlight-x-pos")
    let augmentationOffset = window.getComputedStyle(document.querySelector('.toggle_augmentation'), ':before').getPropertyValue("--highlight-x-pos")

    let stickySubmit = document.querySelector(".sticky-submit");
    let pdfLogo = document.querySelector(".pdfLogo");
    let changeOverlay = document.querySelector(".changeOverlay");
    let submitSection = document.querySelector(".submit-section");

    if (bolEolOffset === "58px") {
      document.querySelector('.toggle_bol_eol_flag').style.setProperty("--highlight-x-pos", "25.5px");
    } else {
      document.querySelector('.toggle_bol_eol_flag').style.setProperty("--highlight-x-pos", "3px");
    }
    if (augmentationOffset === "58px") {
      document.querySelector('.toggle_augmentation').style.setProperty("--highlight-x-pos", "25.5px");
    } else {
      document.querySelector('.toggle_augmentation').style.setProperty("--highlight-x-pos", "3px");
    }

    if (!document.querySelector(".show-config").classList.contains("open")) {
      document.querySelector(".show-config").click();
    }

    resultsWrapper.style.overflow = "visible";
    resultsWrapper.style.left = "485px";
    resultsWrapper.style.height = "auto";
    resultsWrapper.style.backgroundColor = "rgba(0,56,117,.1)";
    resultsWrapper.style.width = "fit-content";
    resultsWrapper.style.marginLeft = "0";
    resultsWrapper.style.paddingRight = "55px";
    resultsWrapper.style.paddingTop = "65px";

    resultsWrapperMaxW.style.width = "1000px";
    resultsWrapperMaxW.style.paddingBottom = "80px";

    submissionForm.style.width = "460px";
    submissionForm.style.paddingLeft = "50px";
    submissionForm.style.paddingTop = "55px";
    submissionForm.style.paddingBottom = "50px";
    submissionForm.style.height = "fit-content";
    submissionForm.style.maxWidth = "460px";
    submissionForm.style.overflow = "visible";
    submissionForm.style.top = "0";

    if (changeOverlay) {
      changeOverlay.style.width = "1000px";
      changeOverlay.style.right = "0px";
      changeOverlay.style.position = "absolute";
    }

    submitSection.style.display = "none";

    stickySubmit.style.position = "";

    pdfLogo.style.display = "block";

    navBar.style.display = "none";

    openConfig.style.display = "none";

    document.body.style.backgroundColor = "white";
    document.body.style.width = "1584px";
    document.body.style.maxWidth = "1584px";

    setTimeout(() => {
      html2canvas(document.body).then(canvas => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF('portrait');
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = (canvas.width * pdfHeight) / canvas.height;
        const xPos = 0;
        const yPos = 0;
        pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, pdfHeight);
        pdf.save(`AESI_Terrastore_sizing_estimate_${formContent.rated_system_power_mw}MW_${formContent.discharge_duration}MWh.pdf`);
      });

      resultsWrapper.style.removeProperty("overflow");
      resultsWrapper.style.removeProperty("left");
      resultsWrapper.style.removeProperty("height");
      resultsWrapper.style.removeProperty("background-color");
      resultsWrapper.style.removeProperty("width");
      resultsWrapper.style.removeProperty("margin-left");
      resultsWrapper.style.removeProperty("padding-right");
      resultsWrapper.style.removeProperty("padding-top");

      resultsWrapperMaxW.style.removeProperty("width");
      resultsWrapperMaxW.style.removeProperty("padding-bottom");

      submissionForm.style.removeProperty("width");
      submissionForm.style.removeProperty("height");
      submissionForm.style.removeProperty("max-width");
      submissionForm.style.removeProperty("overflow");
      submissionForm.style.removeProperty("top");
      submissionForm.style.removeProperty("padding-left");
      submissionForm.style.removeProperty("padding-top");
      submissionForm.style.removeProperty("padding-bottom");

      if (changeOverlay) {
        changeOverlay.style.removeProperty("width");
        changeOverlay.style.removeProperty("right");
        changeOverlay.style.removeProperty("position");
      }

      submitSection.style.removeProperty("display");

      stickySubmit.style.position = "sticky";

      pdfLogo.style.setProperty("display", "none");

      navBar.style.removeProperty("display");

      openConfig.style.removeProperty("display");

      vizFrame.style.setProperty("display", "block")
      vizImage.style.setProperty("display", "none");

      document.body.style.removeProperty("background-color");
      document.body.style.removeProperty("width");
      document.body.style.removeProperty("max-width");
      document.querySelector('.toggle_bol_eol_flag').style.removeProperty("--highlight-x-pos");
      document.querySelector('.toggle_augmentation').style.removeProperty("--highlight-x-pos");
    }, 200)
  };

  return (
    <div className="NavBar" id="NavBar">
      <img onClick={() => navigate("/")} src={logo} alt="logo" />
      {resultsContent.service_life ?
        <div style={{ display: "flex", alignItems: "center" }}>
          {showOverlay ?
            <>
              <button className="disabled">GET QUOTE</button>
              <button className="disabled" style={{ marginLeft: "1em" }}>DOWNLOAD PDF</button>
            </>
            :
            <>
              <button className="red" onClick={() => setShowContactForm(true)}>GET QUOTE</button>
              <button style={{ marginLeft: "1em" }} onClick={sendVisualisationScreenShotMessage}>DOWNLOAD PDF</button>
            </>
          }
        </div>
        : null
      }
    </div >
  )
}