import {useContext} from 'react';
import ResultsContext from '../../../context/ResultsContext';
import '../Results.scss';

export default function ResultsServiceLife() {
    const {resultsContent} = useContext(ResultsContext);
    let INTERACTIVA_URI;
    switch (window.location.host) {
        case process.env.REACT_APP_UAT_URL:
            INTERACTIVA_URI = 'https://tst-aesi.interatica.com/';
            break;
        case process.env.REACT_APP_VIZ_UAT_URL:
            INTERACTIVA_URI = 'https://tst-aesi.interatica.com/';
            break;
        default:
            INTERACTIVA_URI = 'https://dev-aesi.interatica.com/';
            break;
    }

    return (

        <div className="ResultsServiceLife card-basic">
            <div className="card-title-wrapper">
                <h3><span className="text-blue">Visualization</span></h3>
            </div>
            <div className={`graph-card card-inset ${!resultsContent.augmentation ? "graph-card-large" : ""}`}>
                <iframe width="100%" height="700" src={INTERACTIVA_URI} id="visualizationIFrame" style={{display:"block"}}></iframe>
                <img id={"vizImage"} style={{display:"none", height:"100%", width:"100%", objectFit: "contain"}} />
            </div>

        </div>

    )
}