import { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ResultsContext from '../../context/ResultsContext';
import SubmissionFormContext from '../../context/SubmissionFormContext';
import Loader from '../Loader/Loader';
import './GetQuote.scss'

export default function GetQuote({ setShowContactForm }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    country: "",
  })
  const REACT_APP_PROD_URL = process.env.REACT_APP_PROD_URL;
  const DEVELOPMENT_API = process.env.REACT_APP_DEVELOPMENT_API;
  const REACT_APP_PROD_API = process.env.REACT_APP_PROD_API;
  const [API, setAPI] = useState(DEVELOPMENT_API);

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { formContent } = useContext(SubmissionFormContext);
  const { resultsContent } = useContext(ResultsContext);

  const emailReg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  useEffect(() => {
    if (window.location.host === REACT_APP_PROD_URL) {
      setAPI(REACT_APP_PROD_API)
    }
  }, [REACT_APP_PROD_URL, REACT_APP_PROD_API])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    })
  }

  function isButtonDisabled() {
    if (Object.values(formData).includes("") || !emailReg.test(formData.email)) {
      return true
    } else {
      return false
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    let newResultsContent = { ...resultsContent };
    delete newResultsContent.dischargable_ac_energy_graph;
    delete newResultsContent.augmentation;
    delete newResultsContent.desired_discharge_energy_mwh;
    if (e.target.className === "disabled") return;
    setLoading(true)
    const allData = {
      getQuoteData: formData,
      inputsData: formContent,
      outputsData: newResultsContent,
      rawData: resultsContent
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'gC0WrwRfg57dGcjlHIFEIaIfO0z4AsHe7KqVWacV'
      },
      // need to update this to use the real form data once inputs are accepted
      body: JSON.stringify(allData)
    };
    fetch(`${API}/quote`, requestOptions)
      .then(response => response.json())
      .catch((e) => {
        console.error(e)
      }).finally(() => {
        setLoading(false)
        setFormSubmitted(true)
      })
  }
  return (
    <>
      <div className="modal-overlay"></div>
      <Loader isSpinning={loading} />
      <div className="form-modal card-basic">
        <div onClick={() => setShowContactForm(false)} className="close-icon text-blue"><FontAwesomeIcon icon={faXmark} /></div>
        {formSubmitted ?
          <>
            <div className="form-thank-you">
              <div>
                <h2 className="text-blue">Thank you!</h2>
                <p>A member of our team will reach out to you within
                  5 business days with a quote and to discuss your needs.</p>
              </div>
              <div>
                <h3>Have More Questions?</h3>
                <p style={{ fontSize: '14px' }}>Reach out for one of our team members to help you
                  with anymore questions or needs.
                </p>
                <p className="contact-link">
                  <span className="contact-icon text-red">
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <a className="text-blue" href="tel:+9786994999">+1 (978) 699-4999</a>
                </p>
                <p className="contact-link">
                  <span className="contact-icon text-red">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <a className="text-blue" href="mailto:marketing@aesi-ess.com">marketing@aesi-ess.com</a>
                </p>
              </div>
            </div>
          </>
          : <>
            <h2 className="text-blue">Fill Out This Form for a Quote</h2>
            <form>
              <label htmlFor="firstName">First Name</label>
              <input
                onChange={handleInputChange}
                required
                id="firstName"
                name="firstName"
                placeholder="ex. John"
                type="text"
                value={formData.firstName}
              />

              <label htmlFor="lastName">Last Name</label>
              <input
                onChange={handleInputChange}
                required
                id="lastName"
                name="lastName"
                placeholder="ex. Doe"
                type="text"
                value={formData.lastName}
              />

              <label htmlFor="company">Company</label>
              <input
                onChange={handleInputChange}
                required
                id="company"
                name="company"
                placeholder="ex. XYZ Company"
                type="text"
                value={formData.company}
              />

              <label htmlFor="phone">Phone Number</label>
              <input
                onChange={handleInputChange}
                required
                id="phone"
                name="phone"
                placeholder="555-555-5555"
                type="tel"
                value={formData.phone}
              />

              <label htmlFor="email">Business Email</label>
              <input
                onChange={handleInputChange}
                required
                id="email"
                name="email"
                type="email"
                placeholder="johndoe@xyzcompany.com"
                value={formData.email}
              />

              <label htmlFor="country">Country</label>
              <input
                onChange={handleInputChange}
                required
                id="country"
                name="country"
                type="text"
                placeholder="United States"
                value={formData.country}
              />
              <button className={isButtonDisabled() ? "disabled" : ""} onClick={(e) => submitForm(e)} id="submit">SUBMIT</button>
              <p style={{ borderStyle: "dashed", borderWidth: 3, borderColor: '#ac141a', fontSize: '10px', padding: 5, fontWeight: 'bold', textAlign: 'left', color: '#ac141a' }}>By submitting this form, I agree to be contacted by AESI employees via email and phone. I agree to AESI processing my personal data for these purposes and as described in the <a href={'https://aesi-ess.com/privacy-policy/'} >Privacy Policy</a>. I understand I can withdraw my consent at any time.</p>
            </form>
          </>}
      </div>
    </>
  )
}